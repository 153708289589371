import React from 'react';
import { Carousel } from 'react-responsive-carousel'; // Import the carousel
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // Carousel styles
import './PrintifyObituary.css'; // Custom CSS

// Sample employee data with placeholders
const employees = [
  {
    id: 1,
    name: 'Laura Lee',
    position: 'Customer Support',
    email: 'Coming Soon',
    phone: 'Coming Soon',
    image: 'https://via.placeholder.com/150',
  },
  {
    id: 2,
    name: 'Rico Abrencillo',
    position: 'Customer Support',
    email: 'Coming Soon',
    phone: 'Coming Soon',
    image: 'https://via.placeholder.com/150',
  },
  {
    id: 3,
    name: 'Loreen Hamilton',
    position: 'Customer Support',
    email: 'Coming Soon',
    phone: 'Coming Soon',
    image: 'https://via.placeholder.com/150',
  },
  {
    id: 4,
    name: 'Annie Garcia',
    position: 'Customer Support',
    email: 'Coming Soon',
    phone: 'Coming Soon',
    image: 'https://via.placeholder.com/150',
  },
];

// Sample images for the carousel (you can replace these with real images)
const proofImages = [
  {
    id: 1,
    url: 'https://calldaddysatan.com/printify1.png',
    caption: 'Annies shitty service',
  },
  {
    id: 2,
    url: 'https://calldaddysatan.com/prinitfy2.png',
    caption: 'Annie with her empty promises',
  },
  {
    id: 3,
    url: 'https://calldaddysatan.com/printify3.png',
    caption: 'More lies to go around',
  },
  {
    id: 4,
    url: 'https://calldaddysatan.com/printify4.png',
    caption: 'More lies to go around',
  },
  {
    id: 5,
    url: 'https://calldaddysatan.com/printify5.png',
    caption: 'More lies to go around',
  },
  {
    id: 6,
    url: 'https://calldaddysatan.com/printify6.png',
    caption: 'More lies to go around',
  },
];

const PrintifyObituary = () => {
  return (
    <div className="obituary-page">
      <header className="obituary-header">
        <h1>Obituary: Printify</h1>
        <p>Delivery 29 DAYS LATE. Full Refund Promised. Not Delivered</p>
      </header>

      <div className="obituary-content">
        <h2>Cause of Death: Terrible Customer Service & Broken Promises</h2>
        <p>
          Printify, once a promising player in the print-on-demand space, faced its downfall due to an inability to meet customer expectations. 
          A key event was a mishandled order placed on September 6th, 2024, valued at $879.90 for 50 hoodies, which was 29 days late, with a refund that was promised but never delivered.
        </p>

        <h2>Notable Issues</h2>
        <ul>
          <li>Promised a 3-5 day transit time. The order was 28 days in transit.</li>
          <li>Printify’s support representative Annie promised a full refund due to a lost package, but the refund never arrived.</li>
          <li>Left the customer out of pocket and tarnished the business reputation.</li>
        </ul>

        <h2>Date Closed: October 1st, 2024</h2>
        <p>
          After several customer service failures, Printify closed its doors. Below is a detailed timeline of events:
        </p>

        <h3>Timeline of Events</h3>
        <ul>
          <li>
            <strong>September 6, 2024:</strong> An order for 50 hoodies was placed, amounting to $879.90. The estimated delivery time was 3-5 days.
          </li>
          <li>
            <strong>15 days later:</strong> No further updates were provided on the shipment, leading to negative reviews from clients, affecting the business reputation.
          </li>
          <li>
            <strong>Communication with Printify:</strong> After reaching out, customer service (Annie Garcia) promised a refund due to a lost package.
          </li>
          <li>
            <strong>Refund issues:</strong> Printify later offered only a partial refund of $123, which was issued as a credit, contrary to the original promise of a full refund.
          </li>
          <li>
            <strong>Final losses:</strong> Total out-of-pocket loss: $1,871.90, including client refunds and reputational damage.
          </li>
        </ul>
      </div>

      {/* Carousel Section for Proof Images */}
      <section className="proof-carousel-section">
        <h2>Evidence of Events</h2>
        <Carousel
          showArrows={true}
          showThumbs={false}
          infiniteLoop={true}
          autoPlay={true}
          interval={4000}
          showStatus={false}
        >
          {proofImages.map((image) => (
            <div key={image.id}>
              <img src={image.url} alt={`Proof ${image.id}`} />
              <p className="legend">{image.caption}</p>
            </div>
          ))}
        </Carousel>
      </section>

      {/* Employee Section */}
      <section className="employees-section">
        <h2>Meet the Team</h2>
        <div className="employees-grid">
          {employees.map((employee) => (
            <div key={employee.id} className="employee-card">
              <img src={employee.image} alt={employee.name} className="employee-image" />
              <div className="employee-info">
                <h3>{employee.name}</h3>
                <p>{employee.position}</p>
                <p>Email: <a href={`mailto:${employee.email}`}>{employee.email}</a></p>
                <p>Phone: <a href={`tel:${employee.phone}`}>{employee.phone}</a></p>

                {/* Social and Home Icons */}
                <div className="employee-icons">
                  <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">
                    <img src="/facebook-icon.png" alt="Facebook" className="icon" />
                  </a>
                  <a href="/">
                    <img src="/home-icon.png" alt="Home" className="icon" />
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>

      <footer className="obituary-footer">
        <p>Despite the challenges, Printify’s story serves as a lesson for the print-on-demand industry.</p>
      </footer>
    </div>
  );
};

export default PrintifyObituary;