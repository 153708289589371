import React from 'react';
import './ContactDaddySatan.css'; // Import the renamed CSS file
import satanImage from './satan.png'; // Replace with the correct image path

const ContactDaddySatan = () => {
  return (
    <div className="contact-daddy-satan">
      <div className="contact-container">
        {/* Left side: Satan image */}
        <div className="satan-image">
          <img src={satanImage} alt="Satan" />
        </div>

        {/* Right side: Contact info text */}
        <div className="contact-info">
          <h1>Contact Daddy Satan</h1>
          <p>
            Has a company done you wrong? Do you want to even the score? Reach out to us and let us help you get what you deserve.
          </p>
          <p>
            Please reach out to us on Instagram at <a href="https://instagram.com/calldaddysatan" target="_blank" rel="noopener noreferrer">@calldaddysatan</a> and let us know the company you are having problems with, along with any supporting documents.
          </p>
        </div>
      </div>
    </div>
  );
};

export default ContactDaddySatan;