import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import './App.css';
import PostDetails from './PostDetails';
import daddyBanner from './daddybanner1.png'; // Import your banner image
import coffinIcon from './coffin.png'; // Import the coffin icon
import PrintifyObituary from './PrintifyObituary'; // Import Printify Obituary component
import CallDaddySatan from './ContactDaddySatan'; // Import Call Daddy Satan component

// Mock data for the tarot cards
const tarotCards = [
  { id: 1, title: 'Printify', description: 'High-quality print-on-demand services', className: '', link: '/obituary/printify' },
  { id: 2, title: 'Coming soon', description: 'Stay tuned for our next product', className: 'coming-soon' },
  { id: 3, title: 'Coming soon', description: 'More offerings coming soon', className: 'coming-soon' },
  { id: 4, title: 'Coming soon', description: 'More offerings coming soon', className: 'coming-soon' },
  { id: 5, title: 'Coming soon', description: 'More offerings coming soon', className: 'coming-soon' },
  { id: 6, title: 'Coming soon', description: 'More offerings coming soon', className: 'coming-soon' },
  { id: 7, title: 'Coming soon', description: 'More offerings coming soon', className: 'coming-soon' },
  { id: 8, title: 'Coming soon', description: 'More offerings coming soon', className: 'coming-soon' },
];

// Tarot card component for displaying individual tarot cards
const TarotCard = ({ title, description, className, link }) => {
  return (
    <Link to={link} className={`tarot-card ${className}`}>
      <div className="tarot-card-content">
        <h2>{title}</h2>
        <p>{description}</p>
      </div>
    </Link>
  );
};

// Navigation bar component
const NavigationBar = () => {
  return (
    <nav className="navigation-bar">
      <ul>
        <li>
          <Link to="/">
            <img src={coffinIcon} alt="Coffin" className="nav-icon" />
            <span>Home</span>
          </Link>
        </li>
        <li>
          <Link to="/merch">
            <img src={coffinIcon} alt="Coffin" className="nav-icon" />
            <span>Merch</span>
          </Link>
        </li>
        <li>
          <Link to="/call-daddy-satan">
            <img src={coffinIcon} alt="Coffin" className="nav-icon" />
            <span>Call Daddy Satan</span>
          </Link>
        </li>
      </ul>
    </nav>
  );
};

// Home component to render the tarot cards on the homepage
const Home = () => {
  return (
    <div>
      <h1>Welcome to CallDaddySatan</h1>
      <p>Exposing the businesses that should burn in hell.</p>

      {/* Display tarot card style posts directly on the homepage */}
      <div className="company-listings">
        {tarotCards.map((card) => (
          <TarotCard key={card.id} title={card.title} description={card.description} className={card.className} link={card.link} />
        ))}
      </div>
    </div>
  );
};

const App = () => {
  return (
    <Router>
      {/* Banner at the top */}
      <div className="banner-container">
        <img src={daddyBanner} alt="Daddy Satan Banner" className="banner-image" />
      </div>

      {/* Navigation Bar */}
      <NavigationBar />

      {/* Routes for navigating between Home, PostDetails, Printify Obituary, and Call Daddy Satan */}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/posts/:slug" element={<PostDetails />} />
        <Route path="/obituary/printify" element={<PrintifyObituary />} /> {/* New route for Printify Obituary */}
        <Route path="/call-daddy-satan" element={<CallDaddySatan />} /> {/* Route for Call Daddy Satan */}
      </Routes>

      {/* Footer */}
      <footer>
        <p>All rights reserved © CallDaddySatan</p>
      </footer>
    </Router>
  );
};

export default App;