import React from 'react';
import './PostDetails.css';

// Sample data for the companies (replace with actual data)
const companies = [
  {
    name: "PRINTIFY",
    description: "",
    website: "https://www.printify.com",
  },
  {
    name: "COMING SOON",
    description: "",
    website:"",
  },
  {
    name: "COMING SOON",
    description: "",
    website: "",
  },
];

// Tarot card styled component
const TarotCard = ({ name, description, website }) => {
  const isComingSoon = name.toUpperCase() === 'COMING SOON'; // Check if it's a "Coming Soon" card
  return (
    <div className={`tarot-card ${isComingSoon ? 'coming-soon' : ''}`}>
      <div className="card-header">
        <h2>{name}</h2>
      </div>
      <div className="card-body">
        <p>{description}</p>
        {/* Only show the link if a website exists */}
        {website && (
          <a href={website} target="_blank" rel="noopener noreferrer">
            Visit Website
          </a>
        )}
      </div>
    </div>
  );
};

// Main PostDetails component to display tarot-style company posts
const PostDetails = () => {
  return (
    <div className="post-details-container">
      <h1 className="heading">Company Listings</h1>
      <div className="company-listings">
        {companies.map((company, index) => (
          <TarotCard
            key={index}
            name={company.name}
            description={company.description}
            website={company.website}
          />
        ))}
      </div>
    </div>
  );
};

export default PostDetails;